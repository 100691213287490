import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Row } from '@docs/helpers';
import { Playground } from 'docz';
import Button, { ButtonGroup } from './button';
import * as Icons from '../icons';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "buttons"
    }}>{`Buttons`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Buttons allow users to take action and make choices with a single click`}</p>
    </blockquote>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import Button, { ButtonGroup } from '../../../ui/button'
`}</code></pre>
    <h2 {...{
      "id": "types"
    }}>{`Types`}</h2>
    <p>{`Other than the standard solid, a button may have the type `}<inlineCode parentName="p">{`outline`}</inlineCode>{` or `}<inlineCode parentName="p">{`transparent`}</inlineCode>{`. They may also be included as children of the `}<inlineCode parentName="p">{`<ButtonGroup />`}</inlineCode>{`. You will typically give the `}<inlineCode parentName="p">{`<Button />`}</inlineCode>{` children of a `}<inlineCode parentName="p">{`<ButtonGroup />`}</inlineCode>{` the `}<inlineCode parentName="p">{`outline`}</inlineCode>{` prop, though it is not required.`}</p>
    <ul>
      <li parentName="ul">{`Solid buttons are high emphasis, they have a color fill and shadow.`}</li>
    </ul>
    <Playground __position={0} __code={'<Row>\n  <Button>Button</Button>\n  <Button icon>\n    <Icons.Settings width={16} height={16} fill=\"var(--white)\" mr={1} />\n    Button\n  </Button>\n  <Button icon>\n    <Icons.Settings width={16} height={16} fill=\"var(--white)\" />\n  </Button>\n  <Button disabled icon=\"settings\">\n    <Icons.Settings width={16} height={16} fill=\"#bebebe\" mr={1} />\n    Button\n  </Button>\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Row,
      Playground,
      Button,
      ButtonGroup,
      Icons,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Button mdxType="Button">Button</Button>
    <Button icon mdxType="Button">
      <Icons.Settings width={16} height={16} fill='var(--white)' mr={1} />
      Button
    </Button>
    <Button icon mdxType="Button">
      <Icons.Settings width={16} height={16} fill='var(--white)' />
    </Button>
    <Button disabled icon='settings' mdxType="Button">
      <Icons.Settings width={16} height={16} fill='#bebebe' mr={1} />
      Button
    </Button>
  </Row>
    </Playground>
    <ul>
      <li parentName="ul">{`Outline buttons are medium emphasis, they have a 1px stroke.`}</li>
    </ul>
    <Playground __position={1} __code={'<Row>\n  <Button outline>Button</Button>\n  <Button outline icon>\n    <Icons.Settings width={16} height={16} fill=\"#3369a3\" mr={1} />\n    Button\n  </Button>\n  <Button outline icon>\n    <Icons.Settings width={16} height={16} fill=\"#3369a3\" />\n  </Button>\n  <Button disabled outline icon>\n    <Icons.Settings width={16} height={16} fill=\"#bebebe\" mr={1} />\n    Button\n  </Button>\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Row,
      Playground,
      Button,
      ButtonGroup,
      Icons,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Button outline mdxType="Button">Button</Button>
    <Button outline icon mdxType="Button">
      <Icons.Settings width={16} height={16} fill='#3369a3' mr={1} />
      Button
    </Button>
    <Button outline icon mdxType="Button">
      <Icons.Settings width={16} height={16} fill='#3369a3' />
    </Button>
    <Button disabled outline icon mdxType="Button">
      <Icons.Settings width={16} height={16} fill='#bebebe' mr={1} />
      Button
    </Button>
  </Row>
    </Playground>
    <ul>
      <li parentName="ul">{`Transparent buttons are low emphasis, they are typically used for tertiary actions.`}</li>
    </ul>
    <Playground __position={2} __code={'<Row>\n  <Button transparent>Button</Button>\n  <Button transparent icon>\n    <Icons.Settings width={16} height={16} fill=\"#3369a3\" mr={1} />\n    Button\n  </Button>\n  <Button transparent icon>\n    <Icons.Settings width={16} height={16} fill=\"#3369a3\" />\n  </Button>\n  <Button disabled transparent icon>\n    <Icons.Settings width={16} height={16} fill=\"#bebebe\" mr={1} />\n    Button\n  </Button>\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Row,
      Playground,
      Button,
      ButtonGroup,
      Icons,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Button transparent mdxType="Button">Button</Button>
    <Button transparent icon mdxType="Button">
      <Icons.Settings width={16} height={16} fill='#3369a3' mr={1} />
      Button
    </Button>
    <Button transparent icon mdxType="Button">
      <Icons.Settings width={16} height={16} fill='#3369a3' />
    </Button>
    <Button disabled transparent icon mdxType="Button">
      <Icons.Settings width={16} height={16} fill='#bebebe' mr={1} />
      Button
    </Button>
  </Row>
    </Playground>
    <ul>
      <li parentName="ul">{`Button groups are typically used to group a set of related actions.`}</li>
    </ul>
    <Playground __position={3} __code={'<Row>\n  <ButtonGroup>\n    <Button outline icon>\n      <Icons.RatingFilled width={16} height={16} fill=\"#3369a3\" />\n    </Button>\n    <Button outline icon>\n      <Icons.FavoriteFilled width={16} height={16} fill=\"#3369a3\" />\n    </Button>\n    <Button outline icon>\n      <Icons.Message width={16} height={16} fill=\"#3369a3\" />\n    </Button>\n    <Button outline icon>\n      <Icons.Notification width={16} height={16} fill=\"#3369a3\" />\n    </Button>\n    <Button outline icon>\n      <Icons.Settings width={16} height={16} fill=\"#3369a3\" />\n    </Button>\n  </ButtonGroup>\n  <ButtonGroup>\n    <Button outline>Action 1</Button>\n    <Button outline>Action 2</Button>\n  </ButtonGroup>\n  <ButtonGroup>\n    <Button outline icon>\n      <Icons.Settings width={16} height={16} fill=\"#3369a3\" mr={1} />\n      Action 1\n    </Button>\n    <Button outline icon>\n      <Icons.Settings width={16} height={16} fill=\"#3369a3\" mr={1} />\n      Action 2\n    </Button>\n  </ButtonGroup>\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Row,
      Playground,
      Button,
      ButtonGroup,
      Icons,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <ButtonGroup mdxType="ButtonGroup">
      <Button outline icon mdxType="Button">
        <Icons.RatingFilled width={16} height={16} fill='#3369a3' />
      </Button>
      <Button outline icon mdxType="Button">
        <Icons.FavoriteFilled width={16} height={16} fill='#3369a3' />
      </Button>
      <Button outline icon mdxType="Button">
        <Icons.Message width={16} height={16} fill='#3369a3' />
      </Button>
      <Button outline icon mdxType="Button">
        <Icons.Notification width={16} height={16} fill='#3369a3' />
      </Button>
      <Button outline icon mdxType="Button">
        <Icons.Settings width={16} height={16} fill='#3369a3' />
      </Button>
    </ButtonGroup>
    <ButtonGroup mdxType="ButtonGroup">
      <Button outline mdxType="Button">Action 1</Button>
      <Button outline mdxType="Button">Action 2</Button>
    </ButtonGroup>
    <ButtonGroup mdxType="ButtonGroup">
      <Button outline icon mdxType="Button">
        <Icons.Settings width={16} height={16} fill='#3369a3' mr={1} />
        Action 1
      </Button>
      <Button outline icon mdxType="Button">
        <Icons.Settings width={16} height={16} fill='#3369a3' mr={1} />
        Action 2
      </Button>
    </ButtonGroup>
  </Row>
    </Playground>
    <h2 {...{
      "id": "sizes"
    }}>{`Sizes`}</h2>
    <p>{`Other than the standard size, buttons may be `}<inlineCode parentName="p">{`small`}</inlineCode>{` or `}<inlineCode parentName="p">{`large`}</inlineCode>{`.`}</p>
    <ul>
      <li parentName="ul">{`Use small buttons in tables and when space is at a premium.`}</li>
      <li parentName="ul">{`Use large buttons when the typography and white space in the layout requires greater button emphasis.`}</li>
    </ul>
    <Playground __position={4} __code={'<Row>\n  <Button small>Button</Button>\n  <Button small outline>\n    Button\n  </Button>\n  <Button small transparent>\n    Button\n  </Button>\n</Row>\n<Row>\n  <Button>Button</Button>\n  <Button outline>Button</Button>\n  <Button transparent>Button</Button>\n</Row>\n<Row>\n  <Button large>Button</Button>\n  <Button large outline>\n    Button\n  </Button>\n  <Button large transparent>\n    Button\n  </Button>\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Row,
      Playground,
      Button,
      ButtonGroup,
      Icons,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Button small mdxType="Button">Button</Button>
    <Button small outline mdxType="Button">
      Button
    </Button>
    <Button small transparent mdxType="Button">
      Button
    </Button>
  </Row>
  <Row mdxType="Row">
    <Button mdxType="Button">Button</Button>
    <Button outline mdxType="Button">Button</Button>
    <Button transparent mdxType="Button">Button</Button>
  </Row>
  <Row mdxType="Row">
    <Button large mdxType="Button">Button</Button>
    <Button large outline mdxType="Button">
      Button
    </Button>
    <Button large transparent mdxType="Button">
      Button
    </Button>
  </Row>
    </Playground>
    <h2 {...{
      "id": "colors"
    }}>{`Colors`}</h2>
    <p>{`Colors aren't well-supported yet. For now you'll need to override the styles as explained below. Make sure to override the colors for the normal, :hover, and :disabled states.`}</p>
    <h2 {...{
      "id": "overriding-styles--additional-props"
    }}>{`Overriding Styles + Additional Props`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`<Button />`}</inlineCode>{` component is a styled-component underneath. This means that any props you pass in will be forwarded to the underlying DOM `}<inlineCode parentName="p">{`<button/>`}</inlineCode>{`. You can override styles either by using the `}<inlineCode parentName="p">{`style`}</inlineCode>{` or `}<inlineCode parentName="p">{`className`}</inlineCode>{` prop, or wrapping it with `}<inlineCode parentName="p">{`styled`}</inlineCode>{` from styled-components.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Button style={{ padding: 50 }}>Button</Button>

<Button className="p-50">Button</Button>

import styled from 'styled-components'
const MyButton = styled(Button)\`
  padding: 50px;
\`
`}</code></pre>
    <h2 {...{
      "id": "testing"
    }}>{`Testing`}</h2>
    <p>{`Because this component outputs a single `}<inlineCode parentName="p">{`<button />`}</inlineCode>{` element to the DOM, you can treat this component as if it were just a native DOM button when running tests.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      